import dynamic from 'next/dynamic'
import { ParsedUrlQuery } from 'querystring'
import { useCallback, useState } from 'react'

import { FireModalProps } from '@nx/fire/modal'
import { MaintenanceMode } from '@nx/global-types'
import { useUserAuth } from '@nx/hooks/use-user-auth'
import { getTranslate } from '@nx/translations'

import { getRedirectUrl } from './close-login.helper'
import {
  StyledCreateAccountLoading,
  StyledFireModal,
  StyledForgottenPasswordLoading,
  StyledLoginLoading,
} from './fire-login-modal.styles'
import translate from './fire-login-modal.translate.json'
import { ExtraErrorMessagesProps, Interest, ModalScreen } from './types'

const LoginScreenDynamic = dynamic(
  () => import('./screens').then(({ LoginScreen }) => LoginScreen),
  {
    ssr: false,
    loading: StyledLoginLoading,
  }
)

const CreateAccountScreenDynamic = dynamic(
  () =>
    import('./screens').then(({ CreateAccountScreen }) => CreateAccountScreen),
  {
    ssr: false,
    loading: StyledCreateAccountLoading,
  }
)

const ForgottenPasswordScreenDynamic = dynamic(
  () =>
    import('./screens').then(
      ({ ForgottenPasswordScreen }) => ForgottenPasswordScreen
    ),
  {
    ssr: false,
    loading: StyledForgottenPasswordLoading,
  }
)

const SelectInterestsScreenDynamic = dynamic(
  () =>
    import('./screens').then(
      ({ SelectInterestsScreen }) => SelectInterestsScreen
    ),
  {
    ssr: false,
  }
)

const CompletePasswordResetScreenDynamic = dynamic(
  () =>
    import('./screens').then(
      ({ CompletePasswordResetScreen }) => CompletePasswordResetScreen
    ),
  {
    ssr: false,
  }
)

const PasswordConfirmScreenDynamic = dynamic(
  () =>
    import('./screens').then(
      ({ PasswordConfirmScreen }) => PasswordConfirmScreen
    ),
  {
    ssr: false,
  }
)

const { text } = getTranslate(translate)

export function FireLoginModal({
  login,
  resetPassword,
  completePasswordReset,
  createAccount,
  selectInterests,
  isBusinessAccount,
  defaultScreen = 'LOGIN',
  interests,
  hasLoginCancelButton = true,
  closeCallback,
  extraErrorMessages,
  maintenanceMode,
  redirectUrl,
  setIsOpen,
  ...fireModalProps
}: FireLoginModalProps) {
  const [screen, setScreen] = useState<ModalScreen>(defaultScreen)
  const maintenanceModeEnabled = maintenanceMode === MaintenanceMode.partial

  const reset = useCallback(
    () =>
      [
        login,
        resetPassword,
        completePasswordReset,
        createAccount,
        selectInterests,
      ].map((obj) => obj.reset()),
    [
      login,
      resetPassword,
      completePasswordReset,
      createAccount,
      selectInterests,
    ]
  )

  const saveAndClose = () => {
    reset()
    closeCallback?.()
    setIsOpen?.()
  }

  const screens: Record<
    ModalScreen,
    { title?: string; children: React.ReactNode }
  > = {
    LOGIN: {
      title: text('form.logIn'),
      children: (
        <LoginScreenDynamic
          login={login}
          setScreen={setScreen}
          hasLoginCancelButton={hasLoginCancelButton}
          reset={reset}
          redirectUrl={redirectUrl}
          extraErrorMessages={extraErrorMessages}
          setIsOpen={setIsOpen}
        />
      ),
    },
    PASSWORD: {
      title: text('forgotPassword.title'),
      children: (
        <ForgottenPasswordScreenDynamic
          setScreen={setScreen}
          resetPassword={resetPassword}
          reset={reset}
        />
      ),
    },
    'PASSWORD-CONFIRM': {
      title: text('confirmPassword.title'),
      children: <PasswordConfirmScreenDynamic saveAndClose={saveAndClose} />,
    },
    'COMPLETE-PASSWORD-RESET': {
      title: text('completePasswordReset.title'),
      children: (
        <CompletePasswordResetScreenDynamic
          setScreen={setScreen}
          completePasswordReset={completePasswordReset}
          reset={reset}
        />
      ),
    },
    'CREATE-ACCOUNT': {
      title: text(
        maintenanceModeEnabled
          ? 'createAccount.maintenanceModeTitle'
          : 'createAccount.title'
      ),
      children: (
        <CreateAccountScreenDynamic
          setScreen={setScreen}
          isBusinessAccount={false}
          createAccount={createAccount}
          reset={reset}
          maintenanceMode={maintenanceMode}
        />
      ),
    },
    'CREATE-BUSINESS-ACCOUNT': {
      title: text(
        maintenanceModeEnabled
          ? 'createAccount.maintenanceModeTitle'
          : 'createBusinessAccount.title'
      ),
      children: (
        <CreateAccountScreenDynamic
          setScreen={setScreen}
          isBusinessAccount={true}
          createAccount={createAccount}
          reset={reset}
          maintenanceMode={maintenanceMode}
        />
      ),
    },
    'SELECT-INTERESTS': {
      title: undefined,
      children: (
        <SelectInterestsScreenDynamic
          interests={interests}
          saveAndClose={saveAndClose}
          selectInterests={selectInterests}
        />
      ),
    },
  }

  const { title, children } = screens[screen]

  return (
    <StyledFireModal
      $screen={screen}
      isOpen
      setIsOpen={saveAndClose}
      heading={title}
      {...fireModalProps}
    >
      {children}
    </StyledFireModal>
  )
}

export const getRedirectUrlFromParams = (query: ParsedUrlQuery) => {
  let url = '/'

  const params = ['next', 'return_to_url']

  params.forEach((param) => {
    if (query[param]) {
      url = query[param] as string
    }
  })

  return getRedirectUrl(url)
}

export interface FireLoginModalProps
  extends Omit<FireModalProps, 'isOpen' | 'children' | 'setIsOpen'>,
    ReturnType<typeof useUserAuth> {
  defaultScreen?: ModalScreen
  isBusinessAccount?: boolean
  hasLoginCancelButton?: boolean
  interests: {
    data: Interest[]
    isFetching: boolean
    isError: boolean
    enableFetch: () => void
  }
  closeCallback?: () => void
  extraErrorMessages?: (props: ExtraErrorMessagesProps) => React.ReactNode
  maintenanceMode?: MaintenanceMode
  redirectUrl?: string
  setIsOpen: () => void
}
