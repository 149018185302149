const checkAllowedUrl = (url: string) =>
  /(http(s?):\/\/.*\.bonhams.com($|\/.*$)|^\/.*)/.test(url)

export const getRedirectUrl = (url: string) => {
  try {
    const urlObj = new URL(url)
    const searchParams = urlObj.searchParams
    const returnToWidget =
      searchParams.has('return_to_widget') &&
      searchParams.get('return_to_widget')

    if (returnToWidget) {
      searchParams.set('return_to_widget', returnToWidget)
    }

    url = urlObj.toString()
  } catch {
    // do nothing as relative URL
  }

  if (!checkAllowedUrl(url)) {
    url = '/'
  }

  return url
}
